import React from "react"
import Layout from "../components/layout"

const thankYou = props => {
  const getMessage = () => {
    if (
      !props ||
      !props.location ||
      !props.location.state ||
      !props.location.state.prevPath
    )
      return (
        <p>
          Our Team Will Be Reaching Out Soon With Details Confirming Your
          Appointment!
        </p>
      )
    let prevPath = props.location.state.prevPath
    if (prevPath === "cabinet-virtual-quote")
      return (
        <>
          <p>
            Our Team Has Received Your Request And Will Work On Writing Up Your
            Cabinet Painting Quote As Soon As Possible!
          </p>
          <p>
            We Will Send The Quote Over To Your Email And Notify You Through
            Text Message.
          </p>
          <p>
            If Our Team Has Any Questions Or Needs More Information, We Will
            Also Be Reaching Out Shortly To Gather That Information
          </p>
        </>
      )
    else if (prevPath === "request-a-quote")
      return (
        <p>
          Our Team Will Be Reaching Out Soon With Details Confirming Your
          Appointment!
        </p>
      )
    else
      return (
        <p>
          Our Team Will Be Reaching Out Soon With Details Confirming Your
          Appointment!
        </p>
      )
  }
  return (
    <Layout>
      <div className="thankYouContainer">
        <p>Thank You For Choosing Pivot Painters</p>

        {getMessage()}
      </div>
    </Layout>
  )
}

export default thankYou
